import SensorsIcon from "@mui/icons-material/Sensors";
import { Box, styled, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import {
  GRAD_COLOR_1,
  GRAD_COLOR_2,
  GRAD_COLOR_3,
  HUNDRED_K_DRAW,
  TEN_K_DRAW,
  TextGradient,
  TransparentTextGradient,
} from "../../../utils/constants";

namespace S {
  export const MainContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
    height: "31.5rem",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_3,
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  }));

  export const Header = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "4.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    gap: "0.625rem",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  }));

  export const HeaderLeft = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  }));

  export const HeaderPrimaryText = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    fontWeight: 600,
  }));

  export const LiveContainer = styled(Box)(({ theme }) => ({
    height: "1.25rem",
    width: "3.9375rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: theme.palette.custom.bg.live,
    borderRadius: "0.25rem",
    [theme.breakpoints.down("md")]: {
      height: "1.25rem",
      width: "3.9375rem",
      alignSelf: "flex-start",
      marginTop: "0.5625rem",
    },
  }));

  export const LiveText = styled(Typography)(({ theme }) => ({
    color: theme.palette.custom.secondary.secondary_1,
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: 0.4,
  }));

  export const CustomSensorsIcon = styled(SensorsIcon)(() => ({
    fontSize: "1rem",
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));

  export const GradientText = styled(Typography, { shouldForwardProp: (prop) => prop !== "shineText" })<{ shineText: string }>(
    ({ theme, shineText }) => ({
      background: TextGradient,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      backgroundSize: "200px",
      fontSize: "1.0625rem ",
      fontWeight: "500 !important",
      color: TextGradient,
      lineHeight: 1,
      textShadow: `0 0px 0px ${TextGradient}`,
      position: "relative",
      ":after": {
        content: `"${shineText}"`,
        background: TransparentTextGradient,
        webkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
        zIndex: 3,
        animation: "pool-shine 20s linear infinite",
        "@keyframes pool-shine": {
          "0%": {
            backgroundPosition: "-300px",
          },
          "100%": {
            backgroundPosition: "300px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    }),
  );

  export const MessageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    height: "2rem",
    padding: "0 16px",
    width: "100%",
    background: "linear-gradient(90.02deg, #A246D3 0.02%, #994CC2 99.99%)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "2rem",
      height: "auto",
    },
  }));

  export const MessageText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5625rem",
    },
  }));

  export const PartyConeImg1 = styled("img")(({ theme }) => ({
    height: "1.25rem",
    width: "1.25rem",
  }));

  export const PartyConeImg2 = styled(PartyConeImg1)(({ theme }) => ({
    transform: "rotateY(180deg)",
  }));

  export const DotBoxImg1 = styled("img")(({ theme }) => ({
    position: "absolute",
    left: "1rem",
    bottom: "0rem",
    [theme.breakpoints.down("sm")]: {
      left: "0rem",
      display: "none",
    },
  }));

  export const DotBoxImg2 = styled("img")(({ theme }) => ({
    position: "absolute",
    right: "1rem",
    top: "0rem",
    [theme.breakpoints.down("sm")]: {
      right: "0rem",
      display: "none",
    },
  }));

  export const RoundInfoBox = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    height: "3.625rem",
    padding: "0 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: "#16151A66",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      height: "auto",
    },
  }));

  export const RoundAmount = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
  }));

  export const RoundInfo = styled(Box, {
    shouldForwardProp: (prop) => prop !== "currentRound",
  })(({ currentRound }: { currentRound?: Boolean }) => ({ theme }) => ({
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    gap: "0.2rem",
    height: "fit-content",
    padding: "1rem 0",
    borderBottom: `2px solid ${currentRound ? theme.palette.custom.primary.main : "transparent"}`,
    borderRadius: "2px",
    width: "fit-content",
    margin: "0 0.5rem",
    ":before": {
      content: '""',
      position: "absolute",
      left: "50%",
      top: "100%",
      transform: "translate(-50%, -100%)",
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: currentRound && `5px solid ${theme.palette.custom.primary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      height: "2.3125rem",
      padding: "0",
      alignItems: "center",
    },
  }));

  export const SubText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: 1,
    marginRight: "0.3125rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  }));

  export const GradientDrawText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "gradient",
  })(({ gradient }: { gradient: string }) => ({ theme }) => ({
    fontSize: "1.25rem",
    fontWeight: "600",
    background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  export const YearnText = styled("span")(({ theme }) => ({
    fontSize: "0.625rem",
    lineHeight: 1,
    color: theme.palette.custom.secondary.secondary_1,
    opacity: 0.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  }));

  export const SaleOuterContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    height: "21.625rem",
    maxHeight: "21.625rem",
    width: "100%",
    borderRadius: "0 0 0.625rem 0.625rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      maxHeight: "none",
    },
  }));

  export const SaleContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    height: "calc(100% - 1rem)",
    width: "100%",
    margin: "0.5rem 1rem",
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.custom.secondary.secondary_6,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "23.75rem",
      overflow: "auto",
    },
  }));

  export const SaleLeftContainer = styled(Box)(({ theme }) => ({
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    height: "calc(100% - 2rem)",
    width: "50%",
    padding: "0 1rem",
    margin: "1rem 0",
    borderRight: "1px solid #FFFFFF0F",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
      height: "auto",
      overflowY: "unset",
      paddingTop: "0px",
      borderRight: "none",
    },
  }));

  export const SaleRefererContainer = styled(Box)(({ theme }) => ({
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      borderBottom: "1px solid #FFFFFF0F",
    },
  }));

  export const SaleLeftContent = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  }));

  export const SaleRightContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  }));

  export const SaleHeadingGradientText = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: "600",
    background: GRAD_COLOR_3,
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    lineHeight: 1,
    alignSelf: "flex-start",
  }));

  export const InputContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    width: "100%",
    gap: "0.5rem",
    flexDirection: "column",
    paddingBottom: "10px",
  }));

  export const Label = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: "500",
  }));

  export const TicketErrorText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "errorMessage" && prop !== "isVisible",
  })(({ isVisible }: { isVisible: boolean }) => ({ theme }) => ({
    position: "absolute",
    bottom: "-3px",
    right: "0",
    lineHeight: 1,
    whiteSpace: "nowrap",
    color: isVisible ? "red" : "white",
    fontSize: "10px",
    textAlign: "right",
    display: "block",
    ".balance-text": {
      color: theme.palette.custom.accent.accent_1,
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: "6.4px",
    },
  }));

  export const CustomInput = styled(Input)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    paddingLeft: "1rem",
    height: "32px",
    borderRadius: "4px",
    backgroundColor: theme.palette.custom.secondary.secondary_6,
    border: "1px solid #1967FC",
    outline: "0",
    "&:hover": {
      border: "1px solid #1967FC",
      outline: "0",
    },
    "&:focus": {
      border: "1px solid #1967FC",
      outline: "0",
    },
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "input::placeholder": {
      fontSize: "0.85rem",
    },
    "& input:disabled": {
      cursor: "not-allowed",
    },
  }));

  export const SalesRowContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    width: "100%",
    // background: "#16151A24",
    // boxShadow: "0px 4px 4px 0px rgba(89, 89, 89, 0.05)",
    overflowY: "auto",
  }));

  export const PrivateSaleContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    width: "100%",
    height: "4rem",
    borderRadius: "0.625rem",
    background: "#1E1E21",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "0.3125rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      background: "unset",
      boxShadow: "none",
      padding: "0",
      gap: "1rem",
    },
  }));

  export const PrivateSaleSeperator = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "100%",
      height: "auto",
      justifyContent: "space-between",
    },
  }));

  export const FundRisedContained = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.875rem",
  }));

  export const SaleRow = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  export const TextSpan = styled("span")(({ theme }) => ({
    display: "flex",
  }));

  export const SaleSubText = styled(Typography)(({ theme }) => ({
    display: "flex",
    fontSize: "0.75rem",
    fontWeight: "500",
    opacity: "0.6",
  }));

  export const SaleHighlightText = styled(Typography)(({ theme }) => ({
    display: "flex",
    fontSize: "0.75rem",
    fontWeight: "600",
    opacity: "1 !important",
  }));

  export const IBtnContainer = styled(IconButton)(({ theme }) => ({
    background: theme.palette.custom.primary.main,
    maxHeight: "0.625rem",
    maxWidth: "0.625rem",
    height: "0.625rem",
    pointerEvents: "auto",
    fontSize: "0.5rem",
    marginLeft: "0.625rem",
    alignSelf: "center",
    padding: "0.375rem",
    "&:hover": {
      backgroundColor: "#1846dd",
    },
    p: {
      lineHeight: "0.5",
      fontSize: "0.5rem",
    },
  }));

  export const PopupContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "0.2rem !important",
  }));

  export const NormalInfoText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: "500",
    color: theme.palette.custom.secondary.secondary_1,
  }));

  export const InfoRow = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "7.3125rem",
  }));

  export const GreenInfoText = styled(NormalInfoText)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.custom.accent.accent_1,
    width: "1.875rem",
  }));

  export const ColonSpan = styled("span")(() => ({
    width: "0.3125rem",
  }));

  export const HeadText = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: "1.125rem",
  }));

  export const GradientContentText = styled(Typography, { shouldForwardProp: (prop) => prop !== "gradient" })<{ gradient: string }>(
    ({ theme, gradient }) => ({
      fontSize: "0.625rem",
      fontWeight: 700,
      lineHeight: "1.125rem",
      background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        minWidth: "40%",
        textAlign: "left",
      },
    }),
  );

  export const GradientContentTextLg = styled(Typography, { shouldForwardProp: (prop) => prop !== "gradient" })<{ gradient: string }>(
    ({ theme, gradient }) => ({
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "1.2",
      background: gradient === HUNDRED_K_DRAW ? GRAD_COLOR_3 : gradient === TEN_K_DRAW ? GRAD_COLOR_2 : GRAD_COLOR_1,
      webkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        textAlign: "left",
        minWidth: "40%",
      },
    }),
  );
}

export default S;
