import { CSSProperties, ReactNode } from "react";
import { CSSObject } from "@emotion/react";

declare module "@mui/material" {
  // createTheme uses ThemeOptions as argument type which uses PaletteOptions as type for palette property
  interface PaletteOptions {
    custom: {
      primary: {
        main: string;
      };
      secondary: {
        secondary_1: string;
        secondary_2: string;
        secondary_3: string;
        secondary_4: string;
        secondary_5: string;
        secondary_6: string;
      };
      accent: {
        accent_1: string;
        accent_2: string;
        accent_3: string;
        accent_4: string;
      };
      strokes: {
        primary: string;
        transparent: string;
      };
      bg: {
        app: string;
        scroll: string;
        backdrop: string;
        level: string;
        live: string;
      };
      toast: {
        success: string;
        error: string;
        info: string;
        warning: string;
      };
    };
  }

  // The 'Theme' type which uses Palette as type for palette property
  // so we have to modify both PaletteOptions and Palette for better linting and code completion
  interface Palette {
    custom: {
      primary: {
        main: string;
      };
      secondary: {
        secondary_1: string;
        secondary_2: string;
        secondary_3: string;
        secondary_4: string;
        secondary_5: string;
        secondary_6: string;
      };
      accent: {
        accent_1: string;
        accent_2: string;
        accent_3: string;
        accent_4: string;
      };
      strokes: {
        primary: string;
        transparent: string;
      };
      bg: {
        app: string;
        scroll: string;
        backdrop: string;
        level: string;
        live: string;
      };
      toast: {
        success: string;
        error: string;
        info: string;
        warning: string;
      };
    };
  }
}

//custom popover
export type CustomPopoverProps = {
  id: string | undefined;
  isOpen: HTMLElement | null;
  onClose: () => void;
  children?: ReactNode;
  customStyle?: CSSProperties | CSSObject;
  arrowColor?: string;
};

export enum UserType {
  paidUser = "paidUser",
  freeUser = "freeUser",
}

export enum PredictType {
  premium = "premium",
  free = "free",
}
export enum Predict100KType {
  premium = "premium100k",
  free = "free100k",
}

//App status
export enum AppStatus {
  active = "active",
  drawOneKWinner = "drawWinner",
  drawTenKWinner = "draw10kWinner",
  drawHundredKWinner = "draw100kWinner",
  drawStarted = "drawStarted",
  onHoldForDraw = "onHoldForDraw",
}

export enum predictionLoaderType {
  paidPrediction = "paidPrediction",
  freePrediction = "freePrediction",
}

export type UserGuideType = {
  id: string;
  label: string;
  link: string;
};

export enum TourGuide {
  logout = "LOGOUT",
  login = "LOGIN",
  enteredDraw = "ENTERED_DRAW",
  match = "MATCH",
  none = "NONE",
}

export enum Round {
  roundOne = "ROUND_ONE",
  roundTwo = "ROUND_TWO",
  roundThree = "ROUND_THREE",
}
