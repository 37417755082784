import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import W from "../../../utils/widgets/cards/cards.styled";
import S from "./challenge.styled";
import Poster from "../../../assets/images/ChaosGame.png";
import { HUNDRED_K_DRAW, ONE_K_DRAW, PRIMARY, TEN_K_DRAW } from "../../../utils/constants";
import { gameData } from "../../../utils/constants";
import { useGameUrl } from "../../../utils/hooks/selector";
import { Button } from "../../../utils/widgets";
import StarRatings from "../../../utils/widgets/star-rating";

const Challenge = () => {
  //constants
  const gameUrl = useSelector(useGameUrl);
  const filteredData = gameData.filter((val) => val.link === gameUrl);

  return (
    <W.CustomCardWrapper data-tut="tour-game-info">
      {gameUrl ? (
        <>
          <S.ChallengeCard>
            <S.CardHeader>
              <S.HeaderText>CHALLENGE</S.HeaderText>
              <S.GradientText shineText={"#play2yearn"}>#play2yearn</S.GradientText>
            </S.CardHeader>
            <S.CardContent>
              <S.ContentHeader>
                <S.Poster>
                  <S.PosterImage src={filteredData[0]?.image ?? Poster} alt="poster" />
                </S.Poster>
                <S.PosterContent>
                  <S.PosterName>{filteredData[0]?.name ?? "Chaos"}</S.PosterName>
                  <S.StarRating>
                    <StarRatings rating={filteredData[0]?.rating ?? 4.2} size={20} />
                  </S.StarRating>
                  <S.PlaysAndWinnings>
                    <S.SentenceWrap>
                      <S.SubGradientText gradient={ONE_K_DRAW}>Plays:</S.SubGradientText>
                      <S.SubText>{filteredData[0]?.plays ?? "38,847"}</S.SubText>
                    </S.SentenceWrap>
                    <S.SentenceWrap>
                      <S.SubGradientText gradient={ONE_K_DRAW}>Winnings:</S.SubGradientText>
                      <S.SubText>{filteredData[0]?.winnings ?? "1,420"}</S.SubText>
                    </S.SentenceWrap>
                  </S.PlaysAndWinnings>
                </S.PosterContent>
              </S.ContentHeader>

              <S.EntryFee>
                <S.EntryHeaderText>ENTRY FEE</S.EntryHeaderText>
                <S.FeeStructure>
                  <S.FeeText>100$YEARN</S.FeeText>
                  <S.FeeText>1000$YEARN</S.FeeText>
                  <S.FeeText>1000$YEARN</S.FeeText>
                </S.FeeStructure>
              </S.EntryFee>

              <S.EntryFee>
                <S.EntryHeaderText>PERKS</S.EntryHeaderText>

                <S.FeeStructure>
                  <S.SentenceWrap>
                    <S.PerksSubText>PRIZE</S.PerksSubText>
                    <S.GradientPerksText gradient={HUNDRED_K_DRAW}>180 $YEARN</S.GradientPerksText>
                  </S.SentenceWrap>
                  <S.SentenceWrap>
                    <S.PerksSubText>RETURN</S.PerksSubText>
                    <S.GradientPerksText gradient={TEN_K_DRAW}>80 %</S.GradientPerksText>
                  </S.SentenceWrap>
                  <S.SentenceWrap>
                    <S.PerksSubText>TARGET</S.PerksSubText>
                    <S.GradientPerksText gradient={ONE_K_DRAW}>100</S.GradientPerksText>
                  </S.SentenceWrap>
                </S.FeeStructure>
              </S.EntryFee>

              <Button
                disabled={true}
                buttonType={PRIMARY}
                onClick={() => {}}
                containerCustomStyle={{
                  boxSizing: "border-box",
                  width: "100% !important",
                  alignSelf: "center",
                }}
                customStyle={{
                  width: "100% !important",
                  height: "32px",
                }}
              >
                {/* Challenge */}
                coming soon
              </Button>
            </S.CardContent>
          </S.ChallengeCard>
        </>
      ) : (
        <>
          <Box
            sx={{
              minHeight: "24.75rem",
              maxHeight: "24.75rem",
            }}
          ></Box>
        </>
      )}
    </W.CustomCardWrapper>
  );
};

export default Challenge;
