import store from "../store";
import { setAddingYTG } from "../store/slices/user";
import { usdtTokenAddress, yearnTokenAddress } from "../utils/providers/platforms";
import Toast from "../utils/widgets/toast";

export const CURRENT_CHAIN_ID = Number(process.env.REACT_APP_CURRENT_CHAINID);

export const addYTGToWallet = async (signer) => {
  store.dispatch(setAddingYTG(true));
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    if (signer.provider.isMetaMask) {
      const hasAccount = await signer.request({ method: "eth_requestAccounts" });
      if (hasAccount) {
        const isAssetAdded = await signer.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: yearnTokenAddress[CURRENT_CHAIN_ID], // The address that the token is at.
              symbol: process.env.REACT_APP_YTG_TOKEN_SYMBOL, // A ticker symbol or shorthand, up to 5 chars.
              decimals: process.env.REACT_APP_YTG_TOKEN_DECIMALS, // The number of decimals in the token
              image: process.env.REACT_APP_YTG_TOKEN_IMAGE, // A string url of the token logo
            },
          },
        });
        if (isAssetAdded) Toast({ message: "Adding YEARN to wallet has succeed.", type: "success" });
        else Toast({ message: "Adding YEARN to wallet has failed.", type: "error" });
        store.dispatch(setAddingYTG(false));
      }
    } else {
      Toast({ message: "Wallet is not installed.", type: "error" });
      store.dispatch(setAddingYTG(false));
    }
  } catch (error) {
    console.log("Adding YEARN to wallet has failed", error.message);
    store.dispatch(setAddingYTG(false));
  }
};

export const addUSDTToWallet = async (signer) => {
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    if (signer.provider.isMetaMask) {
      const hasAccount = await signer.request({ method: "eth_requestAccounts" });
      if (hasAccount) {
        const isAssetAdded = await signer.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: usdtTokenAddress[CURRENT_CHAIN_ID], // The address that the token is at.
              symbol: process.env.REACT_APP_USDT_TOKEN_SYMBOL, // A ticker symbol or shorthand, up to 5 chars.
              decimals: process.env.REACT_APP_USDT_TOKEN_DECIMALS, // The number of decimals in the token
              image: process.env.REACT_APP_USDT_TOKEN_IMAGE, // A string url of the token logo
            },
          },
        });
        if (isAssetAdded) Toast({ message: "Adding USDT to wallet has succeed.", type: "success" });
        else Toast({ message: "Adding USDT to wallet has failed.", type: "error" });
      }
    } else {
      Toast({ message: "Wallet is not installed.", type: "error" });
    }
  } catch (error) {
    console.log("Adding USDT to wallet has failed", error.message);
  }
};
