import { FC, useState, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, InputAdornment, Tooltip } from "@mui/material";
import { useAccount } from "wagmi";
import S from "./private-sale.styled";
import DotBox from "../../../assets/images/dot-box.svg";
import PartyCone from "../../../assets/images/party-cone.svg";
import { iercContract } from "../../../services/ethereum/contract/iercContract";
import { CONTRACT } from "../../../services/ethereum/contract/web3";
import { lottoContract } from "../../../services/ethereum/contract/web3Contract";
import { setIsFetchingReferrerId, toggleIsBuying } from "../../../store/slices/user";
import { buyTickets } from "../../../store/slices/wallet.actions";
import {
  AMOUNT_PER_ROUND,
  creditPercentage,
  GRADIENT,
  HUNDRED_K_DRAW,
  ONE_K_DRAW,
  PRIMARY,
  salePeriod,
  TEN_K_DRAW,
  ticket25,
  ticket5,
  ticket50,
  ticket80,
  TICKETS_PER_ROUND,
} from "../../../utils/constants";
import { ReferrerContext } from "../../../utils/contexts/ReferrerContext";
import { getEllipsisString, hasDecimals, toFixed, useGetDimensions } from "../../../utils/helpers";
import {
  useDraw100kNumber,
  useDraw1kNumber,
  useDrawAmount,
  useEnteredDraw,
  useHasParticipant,
  useInvitedBy,
  useIsAppLoading,
  useIsBuying,
  useIsFetchingReferrerAddress,
  useIsInitiatingEventstream,
  useIsReady,
  useIsWhitelisted,
  useMaxTickets,
  useSelectedRound,
  useTicketsSold,
  useUserDetails,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Round } from "../../../utils/types";
import { Button, GradientButton } from "../../../utils/widgets";
import GradientContainer from "../../../utils/widgets/GradientContainer";
import PrivateSaleSkeleton from "../../../utils/widgets/skeletons/private-sale-skeleton";
import Toast from "../../../utils/widgets/toast";
import ConnectButton from "../../connect/ConnectButton";
import LinearProgress from "./linar-progress";

const PrivateSale: FC = () => {
  //local-storage
  const cachedTicketNumber = localStorage.getItem("ticketNumber");

  //constructors
  const dispatch = useAppDispatch();

  //state values
  const { isConnected: isWalletConnected, address } = useAccount();

  const context = useContext(ReferrerContext);

  const enteredDraw = useSelector(useEnteredDraw);
  const hasParticipant = useSelector(useHasParticipant);
  const isAppLoading = useSelector(useIsAppLoading);
  const userDetails = useSelector(useUserDetails);
  const isBuying = useSelector(useIsBuying);
  const draw1kNumber = useSelector(useDraw1kNumber);
  const maxTickets = useSelector(useMaxTickets);
  const isReady = useSelector(useIsReady);
  const ticketsSold = useSelector(useTicketsSold);
  const isInitiatingEventstream = useSelector(useIsInitiatingEventstream);
  const drawAmount = useSelector(useDrawAmount);
  const isWhitelisted = useSelector(useIsWhitelisted);
  const invitedBy = useSelector(useInvitedBy);
  const isFetchingReferrerAddress = useSelector(useIsFetchingReferrerAddress);
  const selectedRound = useSelector(useSelectedRound);

  const [errorMessage, setErrorMessage] = useState("");
  const [deposit, setDeposit] = useState("");
  const [allowance, setAllowance] = useState<number>(0);
  const [ticketCount, setTicketCount] = useState<number>(0);
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const draw100kNumber = useSelector(useDraw100kNumber);

  //constants
  const hasSkeleton = isWalletConnected && (isAppLoading || !isReady || (!hasParticipant && !userDetails.id) || enteredDraw);
  const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);
  const balanceMsg = <>Available balance : {<span className="balance-text">{toFixed(Number(userDetails.balance), 4)} USDT</span>}</>;
  // const softCap = useMemo(() => draw100kNumber * drawAmount, [draw100kNumber]);
  const softCap = TICKETS_PER_ROUND[selectedRound] * drawAmount;
  const currentTicket = isInitiatingEventstream && cachedTicketNumber ? cachedTicketNumber : ticketsSold.toString();
  const fundRaised = ((+currentTicket * drawAmount) / softCap) * 100;
  const yearnPerRound = depositAmount ? depositAmount / AMOUNT_PER_ROUND[selectedRound] : 0;
  const whitelistBonus = isWhitelisted ? yearnPerRound * 0.1 : 0;
  const hasReferrer = invitedBy?.address;
  const isSm = useGetDimensions("sm");

  const InputOnChange = (v: string, isMax?: boolean) => {
    const value = parseInt(v) || 0;
    const remainingTickets = maxTickets - (ticketsSold % draw1kNumber);
    const maxDepositAmount = drawAmount * maxTickets;

    if (value > drawAmount - 1) {
      const maxMultipleOf11 = Math.floor(value / drawAmount) * drawAmount;

      if (value >= drawAmount && value <= maxDepositAmount) {
        setDepositAmount(maxMultipleOf11);
        isMax && setDeposit(maxMultipleOf11.toString());
        setTicketCount(maxMultipleOf11 / drawAmount);
      } else if (maxMultipleOf11 > maxDepositAmount) {
        setDepositAmount(drawAmount * remainingTickets);
        isMax && setDeposit((drawAmount * remainingTickets).toString());
        setTicketCount(remainingTickets);
      }
    } else if (value < drawAmount) {
      setDepositAmount(0);
      isMax && setDeposit("0");
      setTicketCount(0);
    }
  };

  const handleBuyTickets = async () => {
    if (!isBuying.loading && depositAmount > 0) {
      dispatch(toggleIsBuying({ loading: true, close: false }));
      dispatch(
        buyTickets(
          depositAmount.toString(),
          { referrerId: context.referrerId, address: context.referrerAddress },
          () => dispatch(toggleIsBuying({ loading: false, close: true })),
          () => fetchAllowance(),
        ),
      );
    }
  };

  const handleOnBlur = async () => {
    if (context.referrerAddress.trim().length && isReady) {
      dispatch(setIsFetchingReferrerId(true));
      try {
        let participant = await lottoContract.participantByAddress(context.referrerAddress);
        if (participant.id !== "0") {
          context.setReferrer({ ...context.referrer, referrerId: participant.id });
          dispatch(setIsFetchingReferrerId(false));
        } else {
          context.setReferrer({ ...context.referrer, referrerId: "" });
          dispatch(setIsFetchingReferrerId(false));
          Toast({ message: `Referrer doesn't exist!`, type: "error" });
        }
      } catch (err: any) {
        dispatch(setIsFetchingReferrerId(false));
        context.setReferrer({ ...context.referrer, referrerId: "" });
        Toast({ message: "Invalid address!", type: "error" });
      }
    }
  };

  useEffect(() => {
    if (!isReady || !address) return;
    fetchAllowance();
  }, [draw1kNumber, isReady, address]);

  useEffect(() => {
    let errorMessage = "";
    if (!isWalletConnected) return;
    const hasNoMoney = +userDetails.balance < drawAmount || depositAmount > +userDetails.balance;
    if (hasNoMoney) errorMessage = `Insufficient balance!`;
    setErrorMessage(errorMessage);
  }, [userDetails.balance, depositAmount, isWalletConnected]);

  //functions
  const fetchAllowance = async () => {
    let tokenAllowance = await iercContract.getAllowance(`${address}`, CONTRACT[CURRENT_CHAIN_ID]);
    setAllowance(Number(tokenAllowance));
    dispatch(toggleIsBuying({ loading: false, close: true }));
  };

  const calcAdditionalYearn = useMemo(() => {
    let additionalYearn = 0;
    if (ticketCount >= 5 && ticketCount < 25) additionalYearn = (yearnPerRound * ticket5) / 100;
    else if (ticketCount >= 25 && ticketCount < 50) additionalYearn = (yearnPerRound * ticket25) / 100;
    else if (ticketCount >= 50 && ticketCount < 80) additionalYearn = (yearnPerRound * ticket50) / 100;
    else if (ticketCount >= 80) additionalYearn = (yearnPerRound * ticket80) / 100;

    return hasDecimals(additionalYearn) ? +additionalYearn.toFixed(2) : additionalYearn;
  }, [ticketCount]);

  const calcTotalYearn = useMemo(() => {
    const totalYearn = yearnPerRound + calcAdditionalYearn + whitelistBonus;

    return hasDecimals(totalYearn) ? +totalYearn.toFixed(2) : totalYearn;
  }, [ticketCount]);

  return (
    <GradientContainer customStyle={{ zIndex: "0", width: "1500px", height: "1500px", top: "-250px", left: "-200px" }}>
      {hasSkeleton ? (
        <PrivateSaleSkeleton />
      ) : (
        <S.MainContainer>
          <S.Header>
            <S.HeaderLeft>
              <S.HeaderPrimaryText>
                PRIVATE SALE
                {isSm && <S.GradientText shineText={"#round1(earlybird)"}>#round1(earlybird)</S.GradientText>}
              </S.HeaderPrimaryText>
              <S.LiveContainer>
                <S.LiveText>LIVE</S.LiveText>
                <S.CustomSensorsIcon />
              </S.LiveContainer>
            </S.HeaderLeft>
            {!isSm && <S.GradientText shineText={"#round1(earlybird)"}>#round1(earlybird)</S.GradientText>}
          </S.Header>
          <S.MessageContainer>
            <S.DotBoxImg1 draggable={false} src={DotBox} alt="dot-box" />
            <S.PartyConeImg1 draggable={false} src={PartyCone} alt="party-cone" />
            <S.MessageText>For every $11 in our private sale, unlock 3 LUCKY ROUNDS</S.MessageText>
            <S.PartyConeImg2 draggable={false} src={PartyCone} alt="party-cone" />
            <S.DotBoxImg2 draggable={false} src={DotBox} alt="dot-box" />
          </S.MessageContainer>
          <S.RoundInfoBox data-tut="tour-buy-ticket-rounds">
            <S.RoundInfo currentRound={selectedRound === Round.roundOne}>
              <S.SubText>Current Round 1</S.SubText>
              <S.RoundAmount>
                <S.GradientDrawText gradient={HUNDRED_K_DRAW}>${AMOUNT_PER_ROUND[Round.roundOne]}</S.GradientDrawText>
                <S.YearnText>/YEARN</S.YearnText>
              </S.RoundAmount>
            </S.RoundInfo>
            <S.RoundInfo currentRound={selectedRound === Round.roundTwo}>
              <S.SubText>Round 2</S.SubText>
              <S.RoundAmount>
                <S.GradientDrawText gradient={ONE_K_DRAW}>${AMOUNT_PER_ROUND[Round.roundTwo]}</S.GradientDrawText>
                <S.YearnText>/YEARN</S.YearnText>
              </S.RoundAmount>
            </S.RoundInfo>
            <S.RoundInfo currentRound={selectedRound === Round.roundThree}>
              <S.SubText>Round 3</S.SubText>
              <S.RoundAmount>
                <S.GradientDrawText gradient={TEN_K_DRAW}>${AMOUNT_PER_ROUND[Round.roundThree]}</S.GradientDrawText>
                <S.YearnText>/YEARN</S.YearnText>
              </S.RoundAmount>
            </S.RoundInfo>
          </S.RoundInfoBox>
          <S.SaleOuterContainer>
            <S.SaleContainer>
              <S.SaleLeftContainer>
                <S.SaleRefererContainer>
                  <S.SaleHeadingGradientText>Referred By</S.SaleHeadingGradientText>

                  <S.InputContainer>
                    <S.Label>You are invited by</S.Label>
                    <S.CustomInput
                      disableUnderline
                      value={hasReferrer ? getEllipsisString(hasReferrer as string, 9, 8) : context.referrerAddress}
                      onChange={(text) => context.setReferrer({ ...context.referrer, referrerAddress: text.target.value })}
                      readOnly={Boolean(hasReferrer)}
                      placeholder={`${isFetchingReferrerAddress ? "Fetching Address..." : "Enter Address"}`}
                      onBlur={handleOnBlur}
                      sx={{ cursor: Boolean(hasReferrer) ? "not-allowed" : "" }}
                    />
                  </S.InputContainer>
                </S.SaleRefererContainer>
                <S.SaleRefererContainer>
                  <S.PrivateSaleContainer>
                    <S.PrivateSaleSeperator>
                      <S.HeadText>Sale Starts At</S.HeadText>
                      <S.GradientContentText gradient={HUNDRED_K_DRAW}>{salePeriod.startsAt}</S.GradientContentText>
                    </S.PrivateSaleSeperator>
                    <S.PrivateSaleSeperator>
                      <S.HeadText>Sale Ends At</S.HeadText>
                      <S.GradientContentText gradient={ONE_K_DRAW}>{salePeriod.endsAt}</S.GradientContentText>
                    </S.PrivateSaleSeperator>
                  </S.PrivateSaleContainer>
                  <S.SaleRow>
                    <S.HeadText>Soft Cap</S.HeadText>
                    <S.GradientContentTextLg gradient={TEN_K_DRAW}>${softCap}</S.GradientContentTextLg>
                  </S.SaleRow>
                  <S.FundRisedContained>
                    <S.HeadText>Fund Raised</S.HeadText>
                    <LinearProgress progress={+fundRaised.toFixed(2)} />
                  </S.FundRisedContained>
                </S.SaleRefererContainer>
                {/* <S.HeadText>Private Sale Starts</S.HeadText>
              <S.GradientContentText gradient={HUNDRED_K_DRAW}>12 UTC, 05 OCT 2023</S.GradientContentText>
              <S.HeadText>Private Sale Ends</S.HeadText>
              <S.GradientContentText gradient={ONE_K_DRAW}>12 UTC, 19 NOV 2023</S.GradientContentText>
              <S.HeadText>Soft Cap</S.HeadText>
              <S.GradientContentText gradient={TEN_K_DRAW}>${softCap}</S.GradientContentText>
              <S.HeadText>Fund Raised</S.HeadText>
              <LinearProgress progress={+fundRaised.toFixed(2)} /> */}
              </S.SaleLeftContainer>
              <S.SaleRightContainer>
                <S.SaleHeadingGradientText>Buy Private Sale</S.SaleHeadingGradientText>

                <S.InputContainer>
                  <S.Label>
                    Min buy ${drawAmount} Max buy ${maxTickets * drawAmount}
                  </S.Label>
                  <S.CustomInput
                    data-tut="tour-buy-ticket-input"
                    placeholder={`Enter multiples of $${drawAmount}`}
                    type="number"
                    disableUnderline
                    disabled={!isWalletConnected || isBuying.loading}
                    value={deposit}
                    onChange={(e) => {
                      setDeposit(e.target.value);
                      InputOnChange(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          buttonType={PRIMARY}
                          customStyle={{
                            fontSize: "0.875rem",
                            padding: "0",
                            marginRight: "0.25rem",
                            background: "transparent",
                            color: "#1846dd",
                            fontWeight: "bold",
                            "&:hover": {
                              background: "transparent",
                            },
                          }}
                          disabled={!isWalletConnected || !isReady || !Number(userDetails.balance) || isBuying.loading}
                          onClick={() => InputOnChange(userDetails.balance, true)}
                        >
                          Max
                        </Button>
                      </InputAdornment>
                    }
                  />
                  {isWalletConnected && (
                    <S.TicketErrorText isVisible={Boolean(errorMessage.trim().length)}>
                      {Boolean(errorMessage.trim().length) ? errorMessage : balanceMsg}
                    </S.TicketErrorText>
                  )}
                </S.InputContainer>

                <S.SalesRowContainer>
                  <S.SaleRow>
                    <S.SaleSubText>Eligible for lucky rounds</S.SaleSubText>
                    <S.TextSpan>
                      <S.SaleSubText>11x</S.SaleSubText>
                      <S.SaleHighlightText>{ticketCount}</S.SaleHighlightText>
                    </S.TextSpan>
                  </S.SaleRow>
                  <S.SaleRow>
                    <S.SaleSubText>Your Contribution</S.SaleSubText>
                    <S.SaleHighlightText>{depositAmount ? `$${depositAmount}` : 0}</S.SaleHighlightText>
                  </S.SaleRow>
                  <S.SaleRow>
                    <S.SaleSubText>$YEARN for your Contribution</S.SaleSubText>
                    <S.SaleHighlightText>{yearnPerRound ? `${yearnPerRound}` : 0}</S.SaleHighlightText>
                  </S.SaleRow>
                  <S.SaleRow>
                    <S.TextSpan>
                      {/* <S.SaleHighlightText>4.95%</S.SaleHighlightText> */}
                      <S.SaleSubText>
                        Additional $YEARN
                        <Tooltip
                          arrow
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                background: "#2D2C31",
                                maxWidth: "15rem",
                                "@media (max-width: 600px)": {
                                  maxWidth: "10rem",
                                },
                              },

                              "& .MuiTooltip-arrow": {
                                "&::before": {
                                  backgroundColor: "#2D2C31",
                                },
                              },
                            },
                          }}
                          title={
                            <S.PopupContainer>
                              <S.NormalInfoText>
                                Buying multiple tickets in a single transaction gives tokens as credit. This credit increases in a linear way, up to
                                the maximum of {80} tickets.
                              </S.NormalInfoText>
                              {Object.keys(creditPercentage).map((ticket, i) => {
                                return (
                                  <S.InfoRow key={`${ticket}_${i}`}>
                                    <S.NormalInfoText>{ticket}</S.NormalInfoText>
                                    <S.GreenInfoText>
                                      <S.ColonSpan>:</S.ColonSpan>
                                      {creditPercentage[ticket]}
                                    </S.GreenInfoText>
                                  </S.InfoRow>
                                );
                              })}
                            </S.PopupContainer>
                          }
                        >
                          <S.IBtnContainer>
                            <p>i</p>
                          </S.IBtnContainer>
                        </Tooltip>
                      </S.SaleSubText>
                    </S.TextSpan>
                    <S.SaleHighlightText>{calcAdditionalYearn}</S.SaleHighlightText>
                  </S.SaleRow>
                  {isWhitelisted && (
                    <S.SaleRow>
                      <S.SaleSubText>Whitelisted Bonus $YEARN</S.SaleSubText>
                      <S.SaleHighlightText>{whitelistBonus ? `${whitelistBonus}` : 0}</S.SaleHighlightText>
                    </S.SaleRow>
                  )}
                  <S.SaleRow>
                    <S.SaleSubText>Total $YEARN</S.SaleSubText>
                    <S.SaleHighlightText>{calcTotalYearn ? `${calcTotalYearn}` : 0}</S.SaleHighlightText>
                  </S.SaleRow>
                </S.SalesRowContainer>
                {isWalletConnected ? (
                  <Box data-tut="tour-ticket-deposit">
                    <GradientButton
                      buttonType={GRADIENT}
                      onClick={handleBuyTickets}
                      disabled={!depositAmount || isBuying.loading || Boolean(errorMessage.trim().length) || context.isBuyNowDisabled}
                      customStyle={{
                        borderRadius: "8px",
                        height: "32px",
                        width: "100%",
                        padding: "4px 32px",
                      }}
                      containerCustomStyle={{
                        marginTop: "auto",
                        boxSizing: "border-box",
                        border: "1px solid white",
                        borderRadius: "8px",
                        width: "100%",
                      }}
                      loading={isBuying.loading}
                    >
                      {allowance < depositAmount ? (isBuying.loading ? "Approving" : "Approve") : isBuying.loading ? "Joining" : "Join Private Sale"}
                    </GradientButton>
                  </Box>
                ) : (
                  <span style={{ marginTop: "auto" }} data-tut="tour-wallet-connect-1">
                    <w3m-button />
                  </span>
                )}
              </S.SaleRightContainer>
            </S.SaleContainer>
          </S.SaleOuterContainer>
        </S.MainContainer>
      )}
    </GradientContainer>
  );
};

export default PrivateSale;
