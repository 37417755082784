import { styled, Box } from "@mui/material";

namespace S {
  export const OuterBar = styled(Box)(({ theme }) => ({
    // width: "246px",
    width: "93%",
    height: "14px",
    background: "#EFB61450",
    borderRadius: "60px",
    position: "relative",
    display: "flex",
    alignItems: "center",
  }));

  export const InnerBar = styled(Box)(({ theme }) => ({
    height: "14px",
    background: "#EFB614",
    borderRadius: "60px",
  }));

  export const ProgressText = styled(Box)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    position: "absolute",
    zIndex: 3,
    right: "8px",
  }));
}
export default S;
