import { Promise } from "bluebird";
import Web3 from "web3";
import store from "../../../store";
import { setConfetti, setParticipantTxnHash, toggleIsBuying } from "../../../store/slices/user";
import { getOneKDrawWinnerDetails, getTenKDrawWinnerDetails } from "../../../store/slices/user.actions";
import { getCurrentChainId } from "../../../store/slices/wallet.actions";
import { UserSlicetypes } from "../../../utils/types/state";
import Toast from "../../../utils/widgets/toast";
import ABI from "../build/abi.json";
import PLATFORM_ABI from "../build/platform_abi.json";
import { CONTRACT, web3 } from "./web3";

export namespace lottoContract {
  let deployed, web3Contract: any;

  export const setup = async (library?: any) => {
    deployed = false;
    let currentChainId = await getCurrentChainId();
    if (library) {
      web3.setProvider(library);
    }
    const myContractAbi: any = {
      80001: ABI.abi as any, // ABI definitions
      56: PLATFORM_ABI.abi as any, // ABI definitions
    };
    web3Contract = new web3.eth.Contract(myContractAbi[currentChainId], CONTRACT[currentChainId]);
    Promise.promisifyAll(web3Contract, { suffix: "Promise" });
    deployed = true;
    return deployed;
  };

  export const getDraw1k = async () => await web3Contract.methods.DRAW_1K().call();

  export const getDraw10k = async () => await web3Contract.methods.DRAW_10K().call();

  export const getDraw100k = async () => await web3Contract.methods.DRAW_100K().call();

  export const getDrawAmount = async () => await web3Contract.methods.DRAW_AMOUNT().call();

  export const getDrawFee = async () => await web3Contract.methods.DRAW_FEE().call();

  export const getMaxTickets = async () => await web3Contract.methods.MAX_TICKETS().call();

  export const getMaxDraws = async () => await web3Contract.methods.MAX_DRAW().call();

  export const getDrawStatus = async () => await web3Contract.methods.getDrawStatus().call();

  export const getIsWhitelisted = async (address: string) => await web3Contract.methods.whitelist(address).call();

  export const participantByAddress = async (address: string) => {
    let participant = await web3Contract.methods.participants(address).call();
    return participant;
  };

  export const participantById = async (id: string) => {
    let participant = await web3Contract.methods.participantsById(id).call();
    return participant;
  };

  export const getLottoOwner = async () => await web3Contract.methods.owner().call();
  export const getReleaseDuration = async () => await web3Contract.methods.getReleaseDuration().call();
  export const getReleasePercentage = async () => await web3Contract.methods.getReleasePercentage().call();

  export const subscribeNewDeposits = (cb?: any) => {
    web3Contract.events
      .NewDeposit()
      .on("connected", async (subscriptionId: string) => {
        let ticketNumber = await getTicketNumber();
        if (cb && ticketNumber) cb(ticketNumber);
      })
      .on("data", async (event: any) => {
        // console.log("event", event);
        let ticketNumber = await getTicketNumber();
        if (cb && ticketNumber) cb(ticketNumber);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDrawWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .DrawWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-winner-connected");
      })
      .on("data", async (event: any) => {
        // console.log(event, "drawWinner");
        if (cb) {
          let drawNumber = await currentDraw();
          let winnerDetails = await getOneKDrawWinnerDetails(drawNumber);
          if (winnerDetails) cb(winnerDetails);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDraw10KWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .Draw10KWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-10k-winner-connected");
      })
      .on("data", async (event: any) => {
        // console.log(event, "draw10KWinner");
        if (cb) {
          let draw10KNumber = await current10KDraw();
          let winnerDetails = await getTenKDrawWinnerDetails(draw10KNumber);
          if (winnerDetails) cb(winnerDetails);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDraw100KWinner = (cb?: (res: UserSlicetypes.IWinner) => void) => {
    web3Contract.events
      .Draw100KWinner()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-10k-winner-connected");
      })
      .on("data", async (event: any) => {
        let winner = event.returnValues;
        // console.log(event, "draw100KWinner");
        if (cb && winner.userId && winner.draw100KNumber && winner.ticketNumber) {
          let participantDetail = await participantById(winner.userId);

          let user = {
            userId: winner.userId,
            drawNumber: winner.draw100KNumber,
            ticketNumber: participantDetail.ticketNumber,
          };
          cb(user);
        }
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeDrawStarted = (cb?: (res: string) => void) => {
    web3Contract.events
      .DrawStarted()
      .on("connected", (subscriptionId: string) => {
        // console.log(subscriptionId, "draw-started-connected");
      })
      .on("data", (event: any) => {
        let currentDraw = event.returnValues.drawNumber;
        // console.log(event, "drawStarted");
        if (cb) cb(currentDraw);
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const subscribeReferrer = (cb?: () => void) => {
    web3Contract.events
      .Referrer()
      .on("connected", (subscriptionId: string) => {})
      .on("data", (event: any) => {
        // console.log(event, "Referrer");
        if (cb) cb();
      })
      .on("error", (error: any) => {
        console.log("error", error);
      });
  };

  export const getPastLogs = async () => {
    let latestBlockNumber = await web3.eth.getBlockNumber().then((res: any) => {
      return Number(res);
    });
    let pastLogs: any = await web3Contract.getPastEventsPromise("NewDeposit", {
      fromBlock: latestBlockNumber - 3400,
      toBlock: "latest",
    });
    // console.log("pastLogs", pastLogs);
    return pastLogs;
  };

  export const currentDraw = async () => {
    let currentDraw: string = await web3Contract.methods.currentDraw().call();
    return currentDraw;
  };

  export const current10KDraw = async () => {
    let current10KDraw: string = await web3Contract.methods.current10KDraw().call();
    return current10KDraw;
  };

  export const getTicketNumber = async () => {
    let ticketNumber: string = await web3Contract.methods.ticketNumber().call();
    return ticketNumber;
  };

  export const getDrawDetails = async (drawNo: number) => {
    let drawDetails: any = await web3Contract.methods.draws(drawNo).call();
    return drawDetails;
  };

  export const getReferralBonusesPercentage = async () => {
    let bonusPercentages = await web3Contract.methods.getReferralBonusesPercentage().call();
    return bonusPercentages;
  };

  export const getFreeUserReferralBonusesPercentage = async () => {
    let bonusPercentages = await web3Contract.methods.getFreeUserReferralBonusesPercentage().call();
    return bonusPercentages;
  };

  export const getClaimableAmount = async (address: string) => {
    let claimable = await web3Contract.methods.getClaimableAmount().call({ from: address });
    return claimable;
  };

  export const getClaimableToken = async (address: string) => {
    let claimable = await web3Contract.methods.getClaimableToken().call({ from: address });
    return claimable;
  };

  //Check this condition to enable claim amount in my earnings
  export const getIsClaimActive = async () => {
    let isClaimable = await web3Contract.methods.isClaimActive().call();
    return isClaimable;
  };

  //Check this condition to enable claim tokens in earned tokens.
  export const getIsWithdrawalEnabled = async () => {
    let isWithdrawable = await web3Contract.methods.isWithdrawalEnabled().call();
    return isWithdrawable;
  };

  export const getUserTickets = async (address: string) => {
    let tickets: string[] = await web3Contract.methods.getTickets(address).call();
    return tickets;
  };

  export const deposit = async (amount: string, referrerId: number, address: string, handleConnecting: () => void, cb: (res: any) => void) => {
    let depositAmount = Web3.utils.toWei(amount, "ether");
    let gasPrice = await web3.eth.getGasPrice();

    web3Contract.methods
      .deposit(referrerId, depositAmount)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
        store.dispatch(toggleIsBuying({ loading: true, close: true }));
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        if (receipt.status) {
          store.dispatch(setParticipantTxnHash(receipt.transactionHash));
          Toast({ message: "Amount deposited successfully!", type: "success" });
          store.dispatch(setConfetti(true));
          if (cb) cb(receipt.events.NewDeposit.returnValues);
        } else Toast({ message: "Deposit was not successful!", type: "error" });
      })
      .on("error", (error: any, receipt: any) => {
        console.log("error", error.message, receipt);
        if (error.message.includes(":")) {
          let msg = error.message.split(":")[0];
          Toast({ message: msg, type: "error" });
        } else Toast({ message: error.message, type: "error" });
        handleConnecting();
      });
  };

  export const claimAmount = async (amount: string, address: string, resetLoaders: () => void) => {
    let amountWei = Web3.utils.toWei(amount, "ether");
    let gasPrice = await web3.eth.getGasPrice();

    web3Contract.methods
      .claimAmount(amountWei)
      .send({ from: address, gaslimit: 1100000, gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        Toast({ message: "Amount claimed successfully!", type: "success" });
        store.dispatch(setConfetti(true));
        resetLoaders();
      })
      .on("error", (error: any) => {
        console.log("error", error.message);
        resetLoaders();
      });
  };

  export const claimToken = async (address: string, resetLoaders: () => void) => {
    let gasPrice = await web3.eth.getGasPrice();

    web3Contract.methods
      .claimToken()
      .send({ from: address, gaslimit: 1100000, gasPrice: gasPrice })
      .on("transactionHash", (hash: any) => {
        // console.log("hash", hash);
      })
      .on("receipt", (receipt: any) => {
        // console.log(receipt, "receipt");
        store.dispatch(setConfetti(true));
        resetLoaders();
      })
      .on("error", (error: any) => {
        console.log("error", error.message);
        resetLoaders();
      });
  };
}
