import { Dispatch, SetStateAction } from "react";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { predictionContract } from "../../services/ethereum/contract/predictionContract";
import { TokenContract } from "../../services/ethereum/contract/tokenContract";
import { CONTRACT, PREDICT_CONTRACT, YEARN_TOKEN, web3 } from "../../services/ethereum/contract/web3";
import { lottoContract } from "../../services/ethereum/contract/web3Contract";
import { REFERRAL_BASE_AMOUNT, FREE_USER, HUNDRED_K_DRAW, NULL_ADDRESS, PAID_USER, NONE } from "../../utils/constants";
import { refrrerType } from "../../utils/contexts/ReferrerContext";
import { getCurrentTenKdraw, getWalletBalance, verifyOwners } from "../../utils/helpers";
import { PredictType, UserType } from "../../utils/types";
import { UserSlicetypes } from "../../utils/types/state";
import Toast from "../../utils/widgets/toast";
import { AppDispatch, RootState } from "../index";
import { clearLocalStorageWithException } from "./../../utils/helpers/index";
import {
  onDisconnect,
  onEnteringDraw,
  onSwitchAccount,
  setIsAppLoading,
  toggleIsBuying,
  updateCurrentDraw,
  updateUserAddress,
  updateWalletBalance,
  updateBalance,
  setPrediction,
  setIsFetchingPredicted,
  setIsWithdrawingTokens,
  setReferralBonus,
  updateCurrent10KDraw,
  setPredictGameToken,
  setIsWithdrawingPredictTokens,
  setUserTickets,
  setIsEnteredDraw,
  setShowTour,
  setIsPredictionSucceed,
  setYearnToken,
} from "./user";
import { fetchEarnings, getInvitedBy, getReferrerIdByAddress } from "./user.actions";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

//Connect to the wallet and fetch user details.
export const connect = (address: string) => async (dispatch: AppDispatch) => {
  // dispatch(setIsAppLoading(true));
  dispatch(updateUserAddress(address));
  dispatch(
    fetchUser(
      address,

      () => {
        // dispatch(setIsAppLoading(false));
      },
    ),
  );
};

export const fetchUserTickets = (account: string) => async (dispatch: AppDispatch) => {
  let ticketNumbers = await lottoContract.getUserTickets(account);
  const includeWinners = ticketNumbers.map((t) => ({ ticketNumber: t, draw: NONE }));
  dispatch(setUserTickets({ ticketNumbers, includeWinners }));
};

export const getTokenBalance = (library: any, account: string) => async (dispatch: AppDispatch) => {
  try {
    let yearnBalance = await TokenContract.getBalance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], account);
    dispatch(setYearnToken(yearnBalance));
  } catch (error) {
    console.log("error", error);
    dispatch(setYearnToken("0"));
  }
};

//Check for participant in subgraph. Enters draw if participant is present alreay, or just connects to the wallet.
const fetchUser = (account: string, onClose?: () => void) => async (dispatch: AppDispatch) => {
  try {
    let participant = await lottoContract.participantByAddress(account);
    let tokenBalance = await iercContract.getBalance(account);
    let currentDraw = await lottoContract.currentDraw();
    // dispatch(fetchPredictedDetails(account, currentDraw));

    if (participant.amount === "0" && !participant.is_free_user && participant.addr === NULL_ADDRESS) {
      if (onClose) onClose();
      dispatch(onEnteringDraw());
      dispatch(updateBalance(tokenBalance));
      return;
    } else dispatch(setIsEnteredDraw(true));

    let ticketNumbers = await lottoContract.getUserTickets(account);
    const includeWinners = ticketNumbers.map((t) => ({ ticketNumber: t, draw: NONE }));
    dispatch(setUserTickets({ ticketNumbers, includeWinners }));
    let referrer = await lottoContract.participantByAddress(participant.referrer);
    let walletBalance = await getWalletBalance(account);
    dispatch(updateWalletBalance(Number(walletBalance)));

    dispatch(
      postConnect(
        {
          id: participant.id,
          address: participant.addr,
          balance: tokenBalance,
          isFreeUser: participant.is_free_user,
          participatedOneKDrawNumber: participant.draw_number,
        },
        currentDraw,
        referrer.id,
      ),
    );
    if (onClose) onClose();
  } catch (e) {
    console.log("fetching user has failed!", e);
    if (onClose) onClose();
  }
};

//Deposit amount to enter the draw, along with referrer(referrerId passed) if any.
const depositAmount =
  (account: string, amount: string, balance: string, referrerDetails: UserSlicetypes.InvitedBy, closeModal: () => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    const isOwner = await verifyOwners(account);
    if (isOwner) Toast({ message: "Restricted account! try to use different account" });
    else {
      const invitedBy = getState().user.invitedBy;
      let referrerId = "";
      let currentDraw = await lottoContract.currentDraw();

      if (invitedBy?.referrerId || invitedBy?.address) {
        referrerId = invitedBy?.referrerId ? invitedBy?.referrerId : invitedBy?.address ? await getReferrerIdByAddress(invitedBy?.address) : "";
      }
      if (referrerDetails?.referrerId || referrerDetails?.address) {
        referrerId = referrerDetails?.referrerId
          ? referrerDetails?.referrerId
          : referrerDetails?.address
          ? await getReferrerIdByAddress(referrerDetails?.address)
          : "";
      }

      await lottoContract.deposit(
        amount,
        Number(referrerId),
        account,
        () => dispatch(toggleIsBuying({ loading: false, close: true })),
        async () => {
          dispatch(toggleIsBuying({ loading: false, close: true }));
          closeModal();
          let participant = await lottoContract.participantByAddress(account);
          let tokenBalance = await iercContract.getBalance(account);
          dispatch(
            postConnect(
              {
                id: participant.id,
                address: participant.addr,
                balance: tokenBalance,
                isFreeUser: participant.is_free_user,
                participatedOneKDrawNumber: participant.draw_number,
              },
              currentDraw,
              referrerId,
            ),
          );
        },
      );
    }
  };

//Deposit amount to buy tickets with or without approval depending upon the available allowance.
export const buyTickets =
  (amount: string, referrerDetails: UserSlicetypes.InvitedBy, closeModal: () => void, fetchAllowance: () => void) =>
  async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    const drawAmount = getState().draw.drawAmount;
    let account = user.userDetails.address;
    const isOwner = await verifyOwners(account);
    if (isOwner) Toast({ message: "Restricted account! try to use different account" });
    else {
      let walletBalance = await getWalletBalance(account);
      dispatch(updateWalletBalance(Number(walletBalance)));
      let tokenBalance = await iercContract.getBalance(account);
      if (Number(tokenBalance) < drawAmount) {
        dispatch(toggleIsBuying({ loading: false, close: true }));
        Toast({ message: "Your token balance is insuffecient! Kindly recharge your token to continue.", type: "error" });
        return;
      }
      if (Number(walletBalance) < 0.005) {
        dispatch(toggleIsBuying({ loading: false, close: true }));
        Toast({ message: "You don't have enough gas fee! Kindly recharge your wallet to continue.", type: "error" });
        return;
      }
      let allowance = await iercContract.getAllowance(account, CONTRACT[CURRENT_CHAIN_ID]);
      let AmountInWei = web3.utils.toWei(amount, "ether");
      if (Number(allowance) < Number(amount)) {
        await iercContract.approve(
          account,
          AmountInWei,
          CONTRACT[CURRENT_CHAIN_ID],
          () => fetchAllowance(),
          () => fetchAllowance(),
        );
      } else {
        dispatch(depositAmount(account, amount, tokenBalance, referrerDetails, closeModal));
      }
    }
  };

export const getCurrentChainId = async () => {
  let currentChainId = await web3.eth.getChainId().then((res) => res);
  return currentChainId;
};

//Change the account and draw details once the user switches to another account in his wallet.
export const onAccountChange =
  (account: string, setReferrer: Dispatch<SetStateAction<refrrerType>>, location: string, isWalletSwitch?: boolean) =>
  async (dispatch: AppDispatch) => {
    isWalletSwitch && dispatch(setIsAppLoading(true));
    dispatch(updateUserAddress(account));
    setReferrer({ referrerAddress: "", referrerId: "" });
    dispatch(onSwitchAccount(location));
    dispatch(
      fetchUser(account, () => {
        isWalletSwitch && dispatch(setIsAppLoading(false));
      }),
    );
  };

export const disconnectWallet = () => (dispatch: AppDispatch, getState: any) => {
  clearLocalStorageWithException();
  dispatch(onDisconnect());
};

//fetching necessary details once the user has successfully entered draw.
const postConnect =
  (
    userDetails: {
      id: string;
      address: string;
      balance: string;
      isFreeUser: boolean;
      participatedOneKDrawNumber: string;
    },
    currentDraw: string,
    referrerId?: string,
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(fetchUserTickets(userDetails.address));
    dispatch(onEnteringDraw({ userDetails, currentDraw }));
    dispatch(getInvitedBy(referrerId));
  };

export const updateCurrentDraws = (appStatus: string, ticketsSold: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  let drawNumber = await lottoContract.currentDraw();
  const draw10kNumber = getState().draw.draw10kNumber;
  const currentTenKDraw = `${getCurrentTenKdraw(ticketsSold, appStatus, draw10kNumber)}`;
  dispatch(updateCurrentDraw(drawNumber));
  dispatch(updateCurrent10KDraw(currentTenKDraw));
};

export const freeDrawWinnerPrediction =
  (ticketNumber: string, draw: string, userType: string, onClose: () => void, reset?: () => void) => async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    const address = user.userDetails.address;
    const freeUser = user.prediction.freeUser;
    let resetLoader = () => (reset ? reset() : null);
    try {
      const isOwner = await verifyOwners(address);
      if (isOwner) {
        resetLoader();
        Toast({ message: "Restricted account! try to use different account" });
      } else {
        let cb = (ticketNumber: string, txnHash: string) => {
          const hasPrediction = Boolean(freeUser[draw]);
          dispatch(setPrediction({ userType: userType, prediction: { [draw]: ticketNumber } }));
          !hasPrediction && dispatch(setIsPredictionSucceed({ type: PredictType.free, draw, prediction: ticketNumber, txnHash }));
          onClose();
        };

        if (draw === HUNDRED_K_DRAW) {
          predictionContract.predictDraw100KWinner(ticketNumber, address, cb, resetLoader);
        } else {
          predictionContract.predictDrawWinner(ticketNumber, address, cb, resetLoader);
        }
      }
    } catch (error) {
      resetLoader();
    }
  };

export const premiumDrawWinnerPrediction =
  (ticketNumber: string, draw: string, userType: string, onClose: () => void, reset?: () => void) => async (dispatch: AppDispatch, getState: any) => {
    const user = getState().user;
    const address = user.userDetails.address;
    const paidUser = user.prediction.paidUser;
    const isOwner = await verifyOwners(address);
    let resetLoader = () => (reset ? reset() : null);
    if (isOwner) {
      resetLoader();
      Toast({ message: "Restricted account! try to use different account" });
    } else {
      try {
        let allowance = await iercContract.getAllowance(address, PREDICT_CONTRACT[CURRENT_CHAIN_ID]);
        let tokenBalance = await iercContract.getBalance(address);
        let walletBalance = await getWalletBalance(address);
        let cb = (ticketNumber: string, txnHash: string) => {
          const hasPrediction = Boolean(paidUser[draw]);
          dispatch(setPrediction({ userType: userType, prediction: { [draw]: ticketNumber } }));
          !hasPrediction && dispatch(setIsPredictionSucceed({ type: PredictType.premium, draw, prediction: ticketNumber, txnHash }));
          onClose();
        };

        if (draw === HUNDRED_K_DRAW) {
          let predictAmount = await predictionContract.getPredictionDraw100KFee();
          let predictAmountEth = web3.utils.fromWei(predictAmount, "ether");
          if (Number(tokenBalance) < Number(predictAmountEth)) {
            resetLoader();
            Toast({ message: "Your token balance is insuffecinet. Kindly recharge and try again!", type: "error" });
            return;
          }
          if (Number(walletBalance) < 0.005) {
            resetLoader();
            Toast({ message: "You don't have enough gas fee. Kindly recharge your Mumbai Testnet account and try again!", type: "error" });
            return;
          }
          if (Number(allowance) < Number(predictAmountEth)) {
            await iercContract.approve(address, predictAmount, PREDICT_CONTRACT[CURRENT_CHAIN_ID], resetLoader, () => {
              predictionContract.premiumPredictDraw100kWinner(ticketNumber, address, cb, resetLoader);
            });
          } else predictionContract.premiumPredictDraw100kWinner(ticketNumber, address, cb, resetLoader);
        } else {
          let predictAmount = await predictionContract.getPredictionDrawFee();
          let predictAmountEth = web3.utils.fromWei(predictAmount, "ether");
          if (Number(tokenBalance) < Number(predictAmountEth)) {
            resetLoader();
            Toast({ message: "Your token balance is insuffecinet. Kindly recharge and try again!", type: "error" });
            return;
          }
          if (Number(walletBalance) < 0.005) {
            resetLoader();
            Toast({ message: "You don't have enough gas fee. Kindly recharge your Mumbai Testnet account and try again!", type: "error" });
            return;
          }
          if (Number(allowance) < Number(predictAmountEth)) {
            await iercContract.approve(address, predictAmount, PREDICT_CONTRACT[CURRENT_CHAIN_ID], resetLoader, () => {
              predictionContract.premiumPredictDrawWinner(ticketNumber, address, cb, resetLoader);
            });
          } else predictionContract.premiumPredictDrawWinner(ticketNumber, address, cb, resetLoader);
        }
      } catch (error) {
        resetLoader();
      }
    }
  };

//Fetch the details of tickets predicted by the connected user.
export const fetchPredictedDetails = (address: string, drawNo: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsFetchingPredicted(true));
    let freePredictedDraw = await predictionContract.getFreePredictedDraw(drawNo, address);
    let freePredictedDraw100k = await predictionContract.getFreePredictedDraw100K(address);
    let premiumPredictedDraw = await predictionContract.getPremiumPredictedDraw(drawNo, address);
    let premiumPredictedDraw100k = await predictionContract.getPremiumPredictedDraw100K(address);

    if (freePredictedDraw !== "0") dispatch(setPrediction({ userType: UserType.freeUser, prediction: { ONE_K_DRAW: freePredictedDraw } }));
    if (freePredictedDraw100k !== "0")
      dispatch(setPrediction({ userType: UserType.freeUser, prediction: { HUNDRED_K_DRAW: freePredictedDraw100k } }));
    if (premiumPredictedDraw !== "0") dispatch(setPrediction({ userType: UserType.paidUser, prediction: { ONE_K_DRAW: premiumPredictedDraw } }));
    if (premiumPredictedDraw100k !== "0")
      dispatch(setPrediction({ userType: UserType.paidUser, prediction: { HUNDRED_K_DRAW: premiumPredictedDraw100k } }));
    dispatch(setIsFetchingPredicted(false));
  } catch (e) {
    console.log("Fetching prediction details has failed!", e);
    dispatch(setIsFetchingPredicted(false));
  }
};

//To withdraw lotto game tokens
export const withdrawTokens = () => async (dispatch: AppDispatch, getState: any) => {
  const account = getState().user.userDetails.address;
  const enteredDraw = getState().user.enteredDraw;
  const isOwner = await verifyOwners(account);
  if (isOwner) Toast({ message: "Restricted account! try to use different account" });
  else {
    let isWithdrawing = getState().user.isWithdrawingTokens;
    if (!isWithdrawing) {
      dispatch(setIsWithdrawingTokens(true));
      let resetLoaders = () => {
        dispatch(setIsWithdrawingTokens(false));
        dispatch(fetchEarnings(account, Boolean(account), enteredDraw));
      };
      lottoContract.claimToken(account, () => resetLoaders());
    }
  }
};

//To withdraw predict game tokens
export const withdrawPredictTokens = () => async (dispatch: AppDispatch, getState: any) => {
  const account = getState().user.userDetails.address;
  const isOwner = await verifyOwners(account);
  if (isOwner) Toast({ message: "Restricted account! try to use different account" });
  else {
    let isWithdrawing = getState().user.isWithdrawingPredictTokens;
    if (!isWithdrawing) {
      dispatch(setIsWithdrawingPredictTokens(true));
      let resetLoaders = () => {
        dispatch(setIsWithdrawingPredictTokens(false));
        dispatch(fetchPredictGameTokens());
      };
      predictionContract.withdrawTokens(account, () => resetLoaders());
    }
  }
};

export const setReferralBonusPercentages = () => async (dispatch: AppDispatch) => {
  let freeReferralBonus = await lottoContract.getFreeUserReferralBonusesPercentage();
  let referralBonus = await lottoContract.getReferralBonusesPercentage();
  let paidBonus = {};
  let freeBonus = {};
  freeReferralBonus.forEach((value: string, index: number) => {
    freeBonus = { ...freeBonus, [index + 1]: (+value / 100) * REFERRAL_BASE_AMOUNT };
  });
  dispatch(setReferralBonus({ userType: FREE_USER, bonus: freeBonus }));
  referralBonus.forEach((value: string, index: number) => {
    paidBonus = { ...paidBonus, [index + 1]: (+value / 100) * REFERRAL_BASE_AMOUNT };
  });
  dispatch(setReferralBonus({ userType: PAID_USER, bonus: paidBonus }));
};

//Fetch predict game claimed and unclaimed token amount
export const fetchPredictGameTokens = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const address = getState().user.userDetails.address;
  let participant = await predictionContract.getPredictGameParticipant(address);
  dispatch(
    setPredictGameToken({ claimed: participant.claimed_token, lastReleased: participant.last_released, unclaimed: participant.unclaimed_token }),
  );
};
