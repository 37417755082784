import { CSSProperties, FC } from "react";
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";
import { SShareLinkPopover } from "./popovers.styled";
import { CustomPopoverProps } from "../../utils/types";
import { Triangle } from "../../utils/widgets";
import CustomPopover from "../../utils/widgets/popover";

interface ShareLinkPopoverProps extends CustomPopoverProps {
  customStyle?: CSSProperties;
  shareUrl: string;
}

const ShareLinkPopover: FC<ShareLinkPopoverProps> = ({ isOpen, id, onClose, customStyle, shareUrl }) => {
  return (
    <>
      {Boolean(isOpen) && <Triangle customStyle={{ top: 40, left: 85 }} />}
      <CustomPopover
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        customStyle={{
          top: 100,
          left: 10,
          ...customStyle,
        }}
      >
        <SShareLinkPopover.Wrapper>
          <SShareLinkPopover.Title>Share this link via</SShareLinkPopover.Title>
          <SShareLinkPopover.IconContainer>
            {/* <SShareLinkPopover.IconWrapper>
              <IconButton>
                <FacebookRoundedIcon />
              </IconButton>
            </SShareLinkPopover.IconWrapper>
            <SShareLinkPopover.IconWrapper>
              <IconButton>
                <TwitterIcon />
              </IconButton>
            </SShareLinkPopover.IconWrapper>
            <SShareLinkPopover.IconWrapper>
              <IconButton>
                <LinkedInIcon />
              </IconButton>
            </SShareLinkPopover.IconWrapper>
            <SShareLinkPopover.IconWrapper>
              <IconButton>
                <TelegramIcon />
              </IconButton>
            </SShareLinkPopover.IconWrapper>
            <SShareLinkPopover.IconWrapper>
              <IconButton>
                <WhatsAppIcon />
              </IconButton>
            </SShareLinkPopover.IconWrapper> */}

            <FacebookShareButton url={shareUrl}>
              <SShareLinkPopover.IconWrapper>
                <IconButton>
                  <FacebookRoundedIcon />
                </IconButton>
              </SShareLinkPopover.IconWrapper>
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl}>
              <SShareLinkPopover.IconWrapper>
                <IconButton>
                  <TwitterIcon />
                </IconButton>
              </SShareLinkPopover.IconWrapper>
            </TwitterShareButton>

            <LinkedinShareButton url={shareUrl}>
              <SShareLinkPopover.IconWrapper>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
              </SShareLinkPopover.IconWrapper>
            </LinkedinShareButton>

            <TelegramShareButton url={shareUrl}>
              <SShareLinkPopover.IconWrapper>
                <IconButton>
                  <TelegramIcon />
                </IconButton>
              </SShareLinkPopover.IconWrapper>
            </TelegramShareButton>

            <WhatsappShareButton url={shareUrl}>
              <SShareLinkPopover.IconWrapper>
                <IconButton>
                  <WhatsAppIcon />
                </IconButton>
              </SShareLinkPopover.IconWrapper>
            </WhatsappShareButton>
          </SShareLinkPopover.IconContainer>
        </SShareLinkPopover.Wrapper>
      </CustomPopover>
    </>
  );
};

export default ShareLinkPopover;
