import { FC } from "react";
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, polygonMumbai } from "wagmi/chains";
import "../assets/css/app.css";
import AppRouters from "../routes/AppRouters";
import CustomProvider from "../utils/providers";

const currentChain = Number(process.env.REACT_APP_CURRENT_CHAINID);

const projectId = process.env.REACT_APP_PROJECT_ID || "";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = currentChain === 56 ? [bsc] : [polygonMumbai];

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

const App: FC = () => {
  return (
    <CustomProvider>
      <WagmiConfig config={wagmiConfig}>
        <AppRouters />
      </WagmiConfig>
    </CustomProvider>
  );
};

export default App;
