import { FC } from "react";
import S from "./linear-progress.styled";

const LinearProgress: FC<{ progress: number }> = ({ progress }) => {
  return (
    <S.OuterBar>
      <S.InnerBar sx={{ width: `${progress < 3 ? 3 : progress}%` }}></S.InnerBar>
      <S.ProgressText>{progress}%</S.ProgressText>
    </S.OuterBar>
  );
};

export default LinearProgress;
