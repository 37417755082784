import _ from "lodash";
import { AppDispatch, RootState } from "..";
import { iercContract } from "../../services/ethereum/contract/iercContract";
import { PredictionToEarn } from "../../services/ethereum/contract/predictionPTE";
import { web3 } from "../../services/ethereum/contract/web3";
import { getContractWinnerQuery, getIsRedeemedReward, getPredictionCount } from "../../services/ethereum/subgraph/query";
import { getWalletBalance, verifyOwners } from "../../utils/helpers";
import { predictionPoolClient } from "../../utils/providers";
import { Pool } from "../../utils/types/state";
import Toast from "../../utils/widgets/toast";
import {
  setIsFetchingPoolEndTime,
  setIsFetchingPoolTotalAmount,
  setIsPredictingPool,
  setIsWithdrawingFund,
  setMinMaxAmount,
  setPoolEndTime,
  setPoolTotalAmount,
} from "./match";
import { toggleIsBuying } from "./user";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

// fetching min max amount
export const fetchMinMaxAmount = (lib: any, contract: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const [minAmount, maxAmount] = await Promise.all([PredictionToEarn.minAmount(lib, contract), PredictionToEarn.maxAmount(lib, contract)]);
    const minEthAmount = web3.utils.fromWei(minAmount, "ether");
    const maxEthAmount = web3.utils.fromWei(maxAmount, "ether");
    dispatch(setMinMaxAmount({ minAmount: minEthAmount, maxAmount: maxEthAmount }));
  } catch (error) {
    console.error("Fetching min max match amount has failed!", error);
  }
};

// Predicting pool
export const predictPool =
  (lib: any, contract: string, pool: string, amount: string, cb: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const address = getState().user.userDetails.address;
      dispatch(setIsPredictingPool(true));
      const isOwner = await verifyOwners(address);

      if (isOwner) Toast({ message: "Restricted account! try to use different account" });
      else {
        let walletBalance = await getWalletBalance(address);
        let tokenBalance = await iercContract.getBalance(address);
        if (Number(walletBalance) < 0.005) {
          dispatch(setIsPredictingPool(false));

          Toast({ message: "You don't have enough gas fee! Kindly recharge your wallet to continue.", type: "error" });
          return;
        }
        if (Number(tokenBalance) < Number(amount)) {
          dispatch(setIsPredictingPool(false));

          Toast({ message: "You don't have enough token! Kindly recharge your wallet to continue.", type: "error" });
          return;
        }
        let allowance = await iercContract.getAllowance(address, contract);
        let AmountInWei = web3.utils.toWei(amount, "ether");

        if (Number(allowance) < Number(amount)) {
          await iercContract.approve(
            address,
            AmountInWei,
            contract,
            () => {
              dispatch(setIsPredictingPool(false));
            },
            async () => {
              if (pool === Pool.poolA) {
                await PredictionToEarn.predictPoolA(lib, contract, address, amount);
              } else await PredictionToEarn.predictPoolB(lib, contract, address, amount);
              cb();
            },
          );
        } else {
          if (pool === Pool.poolA) {
            await PredictionToEarn.predictPoolA(lib, contract, address, amount);
          } else await PredictionToEarn.predictPoolB(lib, contract, address, amount);
          cb();
        }
      }
    } catch (error) {
      console.error("Performing predicting pool has failed!", error);
      dispatch(setIsPredictingPool(false));
    }
  };

// fetching poolA and poolB total amount
export const fetchPoolTotalAmount = (lib: any, contract: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(setIsFetchingPoolTotalAmount(true));

    const [poolATotal, poolBTotal] = await Promise.all([PredictionToEarn.poolATotal(lib, contract), PredictionToEarn.poolBTotal(lib, contract)]);
    const poolAEthTotal = web3.utils.fromWei(poolATotal, "ether");
    const poolBEthTotal = web3.utils.fromWei(poolBTotal, "ether");
    dispatch(setPoolTotalAmount({ poolATotal: poolAEthTotal, poolBTotal: poolBEthTotal }));
  } catch (error) {
    console.error("Fetching pool total amount has failed!", error);
    dispatch(setIsFetchingPoolTotalAmount());
  }
};

// fetching end time
export const fetchPoolEndTime = (lib: any, contract: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(setIsFetchingPoolEndTime(true));

    const endTime = await PredictionToEarn.endTime(lib, contract);
    // console.log("endTime", endTime);

    dispatch(setPoolEndTime(endTime));
  } catch (error) {
    console.error("Fetching pool end time has failed!", error);
    dispatch(setIsFetchingPoolEndTime());
  }
};

// withdraw fund from pool
export const withdrawFund =
  (lib: any, contract: string, amount: string = "0") =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const address = getState().user.userDetails.address;
      dispatch(setIsWithdrawingFund(true));
      await PredictionToEarn.withdrawFund(lib, contract, address, amount);
    } catch (error) {
      console.error("Withdrawing fund has failed!", error);
      dispatch(setIsWithdrawingFund());
    }
  };

export const getContractWinner = async (contractAddress: string) => {
  try {
    const getWinners: any = await predictionPoolClient.request(getContractWinnerQuery, {
      where: {
        predictionAddress: contractAddress,
      },
      where2: {
        predictionAddress: contractAddress,
      },
    });
    if (getWinners.poolAWinners.length === 0 && getWinners.poolBWinners.length === 0 && getWinners.noResults.length > 0) return "no result";
    else if (getWinners.poolAWinners.length > 0 && getWinners.poolBWinners.length === 0) return "poolA";
    else if (getWinners.poolAWinners.length === 0 && getWinners.poolBWinners.length > 0) return "poolB";
    if (getWinners.poolAWinners.length === 0 && getWinners.poolBWinners.length === 0 && getWinners.noResults.length === 0) return null;
    else return null;
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const fetchPredictionCount = async (contractAddress: string) => {
  try {
    const count: {
      predictedPoolAs: {
        id: string;
        sender: string;
      }[];
      predictedPoolBs: {
        id: string;
        sender: string;
      }[];
    } = await predictionPoolClient.request(getPredictionCount, {
      where: {
        predictionAddress: contractAddress,
      },
    });
    let uniqA = _.uniqBy(count.predictedPoolAs, "sender");
    let uniqB = _.uniqBy(count.predictedPoolBs, "sender");

    let countNo = {
      poolA: uniqA.length || 0,
      poolB: uniqB.length || 0,
    };
    // console.log("countNo", countNo);

    return countNo;
  } catch (error) {
    return {
      poolA: 0,
      poolB: 0,
    };
  }
};

export const fetchIsRedeemedReward = async (contractAddress: string, address: any) => {
  try {
    const isRedeemed: {
      redeemedRewards: {
        id: string;
      }[];
    } = await predictionPoolClient.request(getIsRedeemedReward, {
      where: {
        predictionAddress: contractAddress,
        addr: address,
      },
    });
    if (isRedeemed.redeemedRewards.length > 0) return true;
    else return false;
  } catch (error) {
    return null;
  }
};

export const getExpectedRewardX = ({
  poolATotal,
  poolBTotal,
  poolACount,
  poolBCount,
  depositedAmount,
  selectedTeam,
}: {
  poolATotal: number;
  poolBTotal: number;
  poolACount: number;
  poolBCount: number;
  depositedAmount: number;
  selectedTeam: string;
}) => {
  let expectedReward = 1;
  if (selectedTeam === "poolA") {
    if (poolBTotal === 0) expectedReward = 1;
    let rewardPerUser = poolBTotal / (poolACount + 1);
    expectedReward = (rewardPerUser + depositedAmount) / depositedAmount;
  } else {
    if (poolATotal === 0) expectedReward = 1;
    let rewardPerUser = poolATotal / (poolBCount + 1);
    expectedReward = (rewardPerUser + depositedAmount) / depositedAmount;
  }
  // max is 5 min is 1
  return expectedReward > 5 ? 5 : expectedReward < 1 ? 1 : Number(expectedReward.toFixed(1));
};

export const getExpectedRewardExisting = ({
  poolATotal,
  poolBTotal,
  poolACount,
  poolBCount,
}: {
  poolATotal: number;
  poolBTotal: number;
  poolACount: number;
  poolBCount: number;
}) => {
  let expectedRewardA = 1;
  let expectedRewardB = 1;
  if (poolBTotal === 0) expectedRewardA = 1;
  else {
    let rewardPerUserA = poolBTotal / poolACount;
    let rewardPerUserB = poolATotal / poolBCount;
    expectedRewardA = (rewardPerUserA + 1000) / 1000;
    expectedRewardB = (rewardPerUserB + 1000) / 1000;

    // max is 5 min is 1
    expectedRewardA = expectedRewardA > 5 ? 5 : expectedRewardA < 1 ? 1 : Number(expectedRewardA.toFixed(1));
    expectedRewardB = expectedRewardB > 5 ? 5 : expectedRewardB < 1 ? 1 : Number(expectedRewardB.toFixed(1));

    return {
      poolA: expectedRewardA,
      poolB: expectedRewardB,
    };
  }
};
