import { FC } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import S from "./lucky-modal.styled";
import CrownImg from "../../assets/images/crown.svg";
import Stars from "../../assets/images/stars.svg";
import { GRADIENT } from "../../utils/constants";
import { useGetDimensions } from "../../utils/helpers";
import { useIsWhitelisted } from "../../utils/hooks/selector";
import { GradientButton } from "../../utils/widgets";
import GradientModalContainer from "../../utils/widgets/gradient-container";

type LuckyModalProps = {
  handleClose: () => void;
  open: boolean;
};

const LuckyModal: FC<LuckyModalProps> = ({ open, handleClose }) => {
  //constants
  const isSm = useGetDimensions();

  const DetailsData = [
    { title: "STAKING", detail: "Stake for 90 days and APR 100%" },
    { title: "PREDICTING", detail: "Predict the Cricket World Cup and win rewards" },
    { title: "PLAYING", detail: "Play games from skillgaming and earn $YEARN" },
  ];

  //states
  const isWhitelisted = useSelector(useIsWhitelisted);

  //local strage
  const whitelistedUser = localStorage.getItem("Whitelist");

  return (
    // <S.BlurContainer  >
    <GradientModalContainer open={open} onClose={handleClose}>
      <S.OuterContainer>
        <S.ContentContainer isGap={isWhitelisted}>
          {isWhitelisted && <S.HeadingText>WHITELISTED USER DETECTED</S.HeadingText>}
          <S.ImageBox>
            <S.CrownImage src={CrownImg} alt="Crown" />
          </S.ImageBox>
          <S.GreetText>
            <b>{isWhitelisted ? "Congratulations!" : "Welcome!"}</b> {isWhitelisted ? "You are among" : "to YearnTogether"}
            <br /> <span>{isWhitelisted ? "the 10%" : "Private Sale Round"}</span> {isWhitelisted ? "lucky users" : null}
          </S.GreetText>
          <S.SubText>
            {isWhitelisted ? "Already ahead in the race of earning" : "Join now!"}
            <br />
            {isWhitelisted ? "You get " : "Extra "}
            <span>{isWhitelisted ? "10% extra $YEARN" : "$YEARN "}</span>
            {!isWhitelisted ? "for first" : null} {isWhitelisted ? "compared to others" : null}
            {!isWhitelisted && <span>1500 users.</span>}
          </S.SubText>

          <S.DetailContainer>
            {DetailsData.map((val, i) => (
              <S.DetailBox key={i}>
                <S.detailImg src={Stars} alt="stars" />
                <S.AccentBox isColor={val.title}>
                  <S.AccentText>{val.title}</S.AccentText>
                </S.AccentBox>
                <S.DetailText>{val.detail}</S.DetailText>
              </S.DetailBox>
            ))}
          </S.DetailContainer>

          <S.SubText sx={{ marginBottom: "0px" }}>
            <Checkbox
              disableRipple
              checked={!!whitelistedUser}
              onChange={() => {
                localStorage.setItem("Whitelist", "isWhiteListedUser");
              }}
            />
            Don't show again
          </S.SubText>

          <S.ButtonBox>
            <GradientButton
              buttonType={GRADIENT}
              onClick={handleClose}
              customStyle={{
                border: "1px solid #FFFFFF",
                width: !isSm ? "251px" : "180px",
                height: !isSm ? "56px" : "40px",
                padding: "0.25rem 2rem",
              }}
            >
              Start Earning
            </GradientButton>
          </S.ButtonBox>
        </S.ContentContainer>
      </S.OuterContainer>
    </GradientModalContainer>
  );
};

export default LuckyModal;
