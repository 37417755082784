import { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import S from "./header.styled";
import HeaderLogo from "../../../assets/images/HeaderLogo.png";
import HeaderMobileRightContainer from "./HeaderMobileRightContainer";
import HeaderRightContainer from "./HeaderRightContainer";
import TicketsDistributed from "./tickets-dsitributed";

const Header: FC = () => {
  //constants
  const location = useLocation();
  const valid = location.pathname.includes(`/cricket/`);
  const navigate = useNavigate();
  return (
    <S.Header>
      <S.HeaderMainContainer validRoute={valid}>
        <S.HeaderLogo
          src={HeaderLogo}
          onClick={() => {
            navigate("/");
          }}
        />
        {!valid && <TicketsDistributed />}
        <HeaderRightContainer />
        <HeaderMobileRightContainer />
      </S.HeaderMainContainer>
    </S.Header>
  );
};

export default Header;
