import axios from "axios";
import moment from "moment";
import { AppDispatch, RootState } from "..";
import { stakeContract } from "../../services/ethereum/contract/stakeContract";
import { TokenContract } from "../../services/ethereum/contract/tokenContract";
import { YEARN_STAKE_CONTRACT, YEARN_TOKEN, web3 } from "../../services/ethereum/contract/web3";
import { encryptCrypto, getWalletBalance, verifyOwners } from "../../utils/helpers";
import Toast from "../../utils/widgets/toast";
import {
  setLastClaimedTime,
  setIsFetchingMyRewards,
  setIsFetchingMyStakes,
  setMyRewards,
  setMyStakes,
  setStakeClaimAmount,
  toggleIsStaking,
} from "./stake";
import { setConfetti, setYearnToken, toggleIsBuying, updateWalletBalance } from "./user";

const CURRENT_CHAIN_ID: number = Number(process.env.REACT_APP_CURRENT_CHAINID);

//Fetching stakes from contract
export const fetchMyStakes = (isUpdate?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (!isUpdate) dispatch(setIsFetchingMyStakes(true));
    const address = getState().user.userDetails.address;
    const stakes = await stakeContract.getStakedAmount(address);
    let stakesEth = web3.utils.fromWei(stakes, "ether");
    dispatch(setMyStakes(stakesEth));
  } catch (error) {
    console.error("Fetching my stakes have failed!", (error as Error).message);
    dispatch(setMyStakes("0"));
    dispatch(setIsFetchingMyStakes());
  }
};

//Fetching rewards from contract
export const fetchMyRewards = (isUpdate?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (!isUpdate) dispatch(setIsFetchingMyRewards(true));
    const address = getState().user.userDetails.address;
    const rewards = await stakeContract.totalEarnedTokens(address);
    let rewardsEth = web3.utils.fromWei(rewards, "ether");
    dispatch(setMyRewards(rewardsEth));
  } catch (error) {
    console.error("Fetching my Rewards have failed!", (error as Error).message);
    dispatch(setIsFetchingMyRewards());
  }
};

//stake deposit
export const stakeDeposit = (library: any, amount: string, cb?: () => void) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(toggleIsStaking({ loading: true }));
    const address = getState().user.userDetails.address;
    const isOwner = await verifyOwners(address);
    if (isOwner) Toast({ message: "Restricted account! try to use different account" });
    else {
      let walletBalance = await getWalletBalance(address);
      dispatch(updateWalletBalance(Number(walletBalance)));
      if (Number(walletBalance) < 0.005) {
        dispatch(toggleIsStaking({ loading: false }));
        Toast({ message: "You don't have enough gas fee! Kindly recharge your wallet to continue.", type: "error" });
        return;
      }
      let tokenBalance = await TokenContract.getBalance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], address);

      if (Number(tokenBalance) < Number(amount)) {
        dispatch(toggleIsStaking({ loading: false }));
        Toast({ message: "You don't have enough token! Kindly recharge your wallet to continue.", type: "error" });
        return;
      }

      let allowance = await TokenContract.getAllowance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], address, YEARN_STAKE_CONTRACT[CURRENT_CHAIN_ID]);
      let AmountInWei = web3.utils.toWei(amount, "ether");
      if (Number(allowance) < Number(amount)) {
        TokenContract.approve(
          library,
          YEARN_TOKEN[CURRENT_CHAIN_ID],
          address,
          AmountInWei,
          YEARN_STAKE_CONTRACT[CURRENT_CHAIN_ID],
          () => {
            dispatch(toggleIsStaking({ loading: false }));
          },
          async () => {
            await stakeContract.deposit(amount, address, async () => {
              dispatch(fetchMyStakes(true));
              let yearnBalance = await TokenContract.getBalance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], address);
              dispatch(setYearnToken(yearnBalance));
              if (cb) cb();
            });
          },
        );
      } else {
        await stakeContract.deposit(amount, address, async () => {
          dispatch(fetchMyStakes(true));
          let yearnBalance = await TokenContract.getBalance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], address);
          dispatch(setYearnToken(yearnBalance));
          if (cb) cb();
        });
      }
    }
  } catch (error) {
    dispatch(toggleIsStaking({ loading: false }));
    console.error("Performing stake deposit has failed!", (error as Error).message);
  }
};

// fetching stake claimable amount
export const fetchStakeClaimableAmount = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const address = getState().user.userDetails.address;

    const pendingDivs = await stakeContract.getPendingDivs(address);
    let pendingDivsEth = web3.utils.fromWei(pendingDivs, "ether");
    dispatch(setStakeClaimAmount(pendingDivsEth));
  } catch (error) {
    console.error("Fetching stake claimable amount has failed!", (error as Error).message);
  }
};

// claim stake amount
export const claimStakeAmount = (library: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const address = getState().user.userDetails.address;

    await stakeContract.claimDivs(address, async () => {
      dispatch(setConfetti(true));
      dispatch(updateLastClaimedTime(address));
      dispatch(setLastClaimedTime({ canClaim: false, claimAfter: 30 }));
      const yearnBalance = await TokenContract.getBalance(library, YEARN_TOKEN[CURRENT_CHAIN_ID], address);
      dispatch(setYearnToken(yearnBalance));
      // let walletBalance = await getWalletBalance(address);
      // dispatch(updateWalletBalance(Number(walletBalance)));
    });
  } catch (error) {
    console.error("Fetching stake claimable amount has failed!", (error as Error).message);
  }
};

// Last claimed time status
export const fetchLastClaimedTime = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const address = getState().user.userDetails.address;

    const claimRes = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/claim/${address}`);

    if (claimRes.data && claimRes.data.canClaim) dispatch(setLastClaimedTime({ canClaim: true, claimAfter: 0 }));
    else if (claimRes.data && !claimRes.data.canClaim) dispatch(setLastClaimedTime({ canClaim: false, claimAfter: claimRes.data.claimAfter }));
  } catch (error) {
    console.error("Fetching last claimed time status has failed!", (error as Error).message);
  }
};

// Updating Last claimed time
export const updateLastClaimedTime = (address: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const encryptedAccount = encryptCrypto({ address });

    const updateRes = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/claim`, { data: encryptedAccount });
    if (updateRes.data && updateRes.data.status) dispatch(fetchLastClaimedTime());
  } catch (error) {
    console.error("Updating last claimed time has failed!", (error as Error).message);
  }
};
